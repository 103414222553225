import React, { useRef } from 'react';
import './contact.css';
import { MdOutlineEmail } from 'react-icons/md';
import { BsLinkedin } from 'react-icons/bs';
import { BsTelegram } from 'react-icons/bs';
import emailjs from 'emailjs-com';

const Contact = () => {
   const form = useRef();

   const sendEmail = (e) => {
      e.preventDefault();

      emailjs.sendForm('service_aeq02oq', 'template_d7tgt7l', form.current, 'AlFarGaKX3q3xrJWG');
      e.target.reset();
   };
   return (
      <section id="contact">
         <h5>Get In Touch</h5>
         <h2>Contact Me</h2>
         <div className="container contact_container">
            <div className="contact_options">
               <article className="contact_option">
                  <MdOutlineEmail className="contact_option-icon" />
                  <h4>Email</h4>
                  <a href="mailto:mohammadkiaei02@gmail.com" target="_blank" rel="noreferrer">
                     Send Email
                  </a>
               </article>
               <article className="contact_option">
                  <BsLinkedin className="contact_option-icon" />
                  <h4>LinkedIn</h4>
                  <a href="https://www.linkedin.com/in/seyedmohammadmahdikiaei/" target="_blank" rel="noreferrer">
                     Connect On LinkedIn
                  </a>
               </article>
               <article className="contact_option">
                  <BsTelegram className="contact_option-icon" />
                  <h4>Telegram</h4>
                  <a href="https://t.me/kiaei02" target="_blank" rel="noreferrer">
                     Send Message
                  </a>
               </article>
            </div>
            <form ref={form} onSubmit={sendEmail}>
               <input type="text" name="name" placeholder="Name" required />
               <input type="email" name="email" placeholder="Email" required />
               <textarea name="message" rows="6" placeholder="Your Message" required></textarea>
               <button type="submit" className="btn btn-primary">
                  Submit Message
               </button>
            </form>
         </div>
      </section>
   );
};

export default Contact;
