import React from 'react';
import './about.css';
import { FaAward } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { VscFolderLibrary } from 'react-icons/vsc';

const about = () => {
   return (
      <section id="about">
         <h5>Get To Know</h5>
         <h2>About Me</h2>
         <div className="container about_container">
            <div className="about_me">
               <div className="about_me-img">
                  <img src="https://github.com/mohammadkiaei/kiaei.info/blob/master/kia-about.png?raw=true" alt="Mohammad Kiaei" />
               </div>
            </div>
            <div className="about_content">
               <div className="about_cards">
                  <article className="about_card">
                     <FaAward className="about_icon" />
                     <h5>Certificates</h5>
                     <small>+5 International</small>
                  </article>
                  <article className="about_card">
                     <FiUsers className="about_icon" />
                     <h5>Clients</h5>
                     <small>+10 Clients</small>
                  </article>
                  <article className="about_card">
                     <VscFolderLibrary className="about_icon" />
                     <h5>Projects</h5>
                     <small>+90 GitHub Repo</small>
                  </article>
               </div>
               <p>I'm a self-taught front-end developer with experience in React. I've completed several projects using this framework to enhance my skills. I've also completed courses from universities like John Hopkins, Duke, and Michigan via Coursera. I'm eager to tackle new challenges and continually improve my skills.</p>
               <a href="#contact" className="btn btn-primary">
                  Let's Talk
               </a>
            </div>
         </div>
      </section>
   );
};

export default about;
