import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { BsGithub } from 'react-icons/bs';
import { BsMedium } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';
import { BsTelegram } from 'react-icons/bs';

const HeaderSocials = () => {
   return (
      <div className="header_socials">
         <a href="https://www.linkedin.com/in/seyedmohammadmahdikiaei/" target="_blank" rel="noreferrer">
            <BsLinkedin />
         </a>
         <a href="https://github.com/mohammadkiaei" target="_blank" rel="noreferrer">
            <BsGithub />
         </a>
         <a href="https://mohammadkiaei.medium.com/" target="_blank" rel="noreferrer">
            <BsMedium />
         </a>
         <a href="https://t.me/kia_hints" target="_blank" rel="noreferrer">
            <BsTelegram />
         </a>
         <a href="https://www.instagram.com/mohammad.kiaei02/" target="_blank" rel="noreferrer">
            <BsInstagram />
         </a>
      </div>
   );
};

export default HeaderSocials;
