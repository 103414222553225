import React from 'react';
import './footer.css';
import { BsLinkedin } from 'react-icons/bs';
import { BsGithub } from 'react-icons/bs';
import { BsMedium } from 'react-icons/bs';
import { BsTelegram } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';

const footer = () => {
   return (
      <footer>
         <a href="/" className="footer_logo">
            Mohammad Kiaei
         </a>
         <ul className="permalinks">
            <li>
               <a href="/">Home</a>
            </li>
            <li>
               <a href="#about">About</a>
            </li>
            <li>
               <a href="#experience">Experience</a>
            </li>
            <li>
               <a href="#services">Services</a>
            </li>
            <li>
               <a href="#portfolio">Portfolio</a>
            </li>
            <li>
               <a href="#contact">Contact</a>
            </li>
         </ul>
         <div className="footer_socials">
            <a href="https://www.linkedin.com/in/seyedmohammadmahdikiaei/" target="_blank" rel="noreferrer">
               <BsLinkedin />
            </a>
            <a href="https://github.com/mohammadkiaei" target="_blank" rel="noreferrer">
               <BsGithub />
            </a>
            <a href="https://mohammadkiaei.medium.com/" target="_blank" rel="noreferrer">
               <BsMedium />
            </a>
            <a href="https://t.me/kia_hints" target="_blank" rel="noreferrer">
               <BsTelegram />
            </a>
            <a href="https://www.instagram.com/mohammad.kiaei02/" target="_blank" rel="noreferrer">
               <BsInstagram />
            </a>
         </div>
         <div className="footer_copyright">
            <small>&copy; Mohammad Kiaei - All Right Reserved</small>
         </div>
      </footer>
   );
};

export default footer;
