import React from 'react';
import Me from '../../assets/MohammadKiaei.pdf';
const CTA = () => {
   return (
      <div className="cta">
         <a href={Me} download className="btn">
            Download Resume
         </a>
         <a href="#contact" className="btn btn-primary">
            Let's Talk
         </a>
      </div>
   );
};

export default CTA;
