import React from 'react';
import './experience.css';
import { BsPatchCheckFill } from 'react-icons/bs';
import { PiCertificateBold } from 'react-icons/pi';

const experience = () => {
   return (
      <section id="experience">
         <h5>What Skills I Have</h5>
         <h2>My Experience</h2>
         <div className="container experience_container">
            <div className="experience_frontend">
               <h3>My Skills</h3>
               <div className="experience_content">
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>HTML</h4>
                  </article>
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>CSS</h4>
                  </article>
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>Bootstrap</h4>
                  </article>
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>Tailwind</h4>
                  </article>
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>Javascript</h4>
                  </article>
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>React</h4>
                  </article>
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>Sass</h4>
                  </article>
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>Figma</h4>
                  </article>
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>API</h4>
                  </article>
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>Git & GitHub</h4>
                  </article>
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>Wordpress</h4>
                  </article>
                  <article className="experience_detail">
                     <BsPatchCheckFill className={'experience_detail-icon'} />
                     <h4>SEO</h4>
                  </article>
               </div>
            </div>
            <div className="certificates">
               <h3>My Certificates</h3>
               <article className="certificates_detail">
                  <div>
                     <PiCertificateBold className={'certificates_detail-icon'} />
                  </div>
                  <div>
                     <h4>Programming Foundations with JavaScript, HTML and CSS</h4>
                     <small>
                        Duke University -{' '}
                        <a href="https://www.coursera.org/account/accomplishments/verify/WDFXQT8HN994" target="_blank" rel="noreferrer">
                           Watch certificate
                        </a>
                     </small>
                  </div>
               </article>
               <article className="certificates_detail">
                  <div>
                     <PiCertificateBold className={'certificates_detail-icon'} />
                  </div>
                  <div>
                     <h4>HTML, CSS, and Javascript for Web Developers</h4>
                     <small>
                        Johns Hopkins University -{' '}
                        <a href="https://www.coursera.org/account/accomplishments/verify/SXRLTEMD6HLY" target="_blank" rel="noreferrer">
                           Watch certificate
                        </a>
                     </small>
                  </div>
               </article>
               <article className="certificates_detail">
                  <div>
                     <PiCertificateBold className={'certificates_detail-icon'} />
                  </div>
                  <div>
                     <h4>Front-End Web UI Frameworks and Tools: Bootstrap 4</h4>
                     <small>
                        The Hong Kong University of Science and Technology -{' '}
                        <a href="https://www.coursera.org/account/accomplishments/verify/TSASG8S9TST9" target="_blank" rel="noreferrer">
                           Watch certificate
                        </a>
                     </small>
                  </div>
               </article>
               <article className="certificates_detail">
                  <div>
                     <PiCertificateBold className={'certificates_detail-icon'} />
                  </div>
                  <div>
                     <h4>Introduction to HTML5</h4>
                     <small>
                        University of Michigan -{' '}
                        <a href="https://www.coursera.org/account/accomplishments/verify/VAE4QKQ49LG6" target="_blank" rel="noreferrer">
                           Watch certificate
                        </a>
                     </small>
                  </div>
               </article>
               <article className="certificates_detail">
                  <div>
                     <PiCertificateBold className={'certificates_detail-icon'} />
                  </div>
                  <div>
                     <h4>Introduction to CSS3</h4>
                     <small>
                        University of Michigan -{' '}
                        <a href="https://www.coursera.org/account/accomplishments/verify/24Z6GK4LZLGP" target="_blank" rel="noreferrer">
                           Watch certificate
                        </a>
                     </small>
                  </div>
               </article>
            </div>
         </div>
      </section>
   );
};

export default experience;
