import React from 'react';
import './nav.css';
import { BiHomeAlt } from 'react-icons/bi';
import { AiOutlineUser } from 'react-icons/ai';
import { MdWork } from 'react-icons/md';
import { FaLaptopCode } from 'react-icons/fa';
import { BiMessageSquareDetail } from 'react-icons/bi';
import { useState } from 'react';

const Nav = () => {
   const [activeNav, setActiveNav] = useState('#');
   return (
      <nav>
         <a href="#intro" onClick={() => setActiveNav('#intro')} className={activeNav === '#intro' ? 'active' : ''}>
            <BiHomeAlt />
         </a>
         <a href="#about" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}>
            <AiOutlineUser />
         </a>
         <a href="#experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}>
            <MdWork />
         </a>
         <a href="#portfolio" onClick={() => setActiveNav('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}>
            <FaLaptopCode />
         </a>
         <a href="#contact" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}>
            <BiMessageSquareDetail />
         </a>
      </nav>
   );
};

export default Nav;
