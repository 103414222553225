import React from 'react';
import './header.css';
import CTA from './CTA';
import HeaderSocials from './HeaderSocials';
import me from '../../assets/MohammadKiaei.png';

const Header = () => {
   return (
      <header id="intro">
         <div className="container header_container">
            <h5>Hello I'm</h5>
            <h1>Mohammad Kiaei</h1>
            <h3 className="text-light">FrontEnd Developer</h3>
            <CTA />
            <HeaderSocials />
            <div className="me">
               <img src={me} alt="Mohammad Kiaei" />
            </div>
            <a href="#contact" className="scroll_down">
               Scroll Down
            </a>
         </div>
      </header>
   );
};

export default Header;
