import React from 'react';
import './portfolio.css';

const data = [
   {
      id: 1,
      title: 'Quote Generator',
      img: 'https://github.com/mohammadkiaei/quote-generator/raw/master/quote-generator-1.png',
      github: 'https://github.com/mohammadkiaei/quote-generator',
      demo: 'https://mohammadkiaei.github.io/quote-generator/',
   },
   {
      id: 2,
      title: 'Shopping cart',
      img: 'https://github.com/mohammadkiaei/react-shopping-cart/blob/master/src/assets/img/ReactShoppingCart2.png?raw=true',
      github: 'https://github.com/mohammadkiaei/react-shopping-cart',
      demo: 'https://react-shopping-cart-kia.netlify.app/',
   },
   {
      id: 3,
      title: 'React Crypto',
      img: 'https://github.com/mohammadkiaei/react-crypto/blob/master/src/img/ReactCrypto.png?raw=true',
      github: 'https://github.com/mohammadkiaei/react-crypto',
      demo: 'https://react-crypto-kia.netlify.app/',
   },
   {
      id: 4,
      title: 'React Form Validation',
      img: 'https://github.com/mohammadkiaei/react-form/blob/master/src/img/ReactForm1.png?raw=true',
      github: 'https://github.com/mohammadkiaei/react-form',
      demo: 'https://react-form-kia.netlify.app/',
   },
   {
      id: 5,
      title: 'Tab Music',
      img: 'https://github.com/mohammadkiaei/Tap-Music/raw/master/tapMusic.png',
      github: 'https://github.com/mohammadkiaei/Tap-Music',
      demo: 'https://mohammadkiaei.github.io/Tap-Music/',
   },
   {
      id: 6,
      title: 'Drink Water',
      img: 'https://raw.githubusercontent.com/mohammadkiaei/drink-water/master/drinkwater2.png?raw=true',
      github: 'https://github.com/mohammadkiaei/drink-water',
      demo: 'https://mohammadkiaei.github.io/drink-water/',
   },
];

const Portfolio = () => {
   return (
      <section id="portfolio">
         <h5>My Recent Work</h5>
         <h2>Portfolio</h2>
         <div className="container portfolio_container">
            {data.map(({ id, title, img, github, demo }) => {
               return (
                  <article key={id} className="portfolio_item">
                     <div className="portfolio_item-img">
                        <img src={img} alt={title} />
                     </div>
                     <h3>{title}</h3>
                     <div className="portfolio_item-cta">
                        <a href={github} target="_blank" rel="noreferrer" className="btn">
                           GitHub Repo
                        </a>
                        <a href={demo} target="_blank" rel="noreferrer" className="btn btn-primary">
                           Live demo
                        </a>
                     </div>
                  </article>
               );
            })}
         </div>
         <a className="btn btn-primary git" href="https://github.com/mohammadkiaei?tab=repositories" target="_blank" rel="noreferrer">
            SEE ALL REPOSITORIES & PROJECTS
         </a>
      </section>
   );
};

export default Portfolio;
